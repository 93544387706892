import React from "react";
export default function FeaturedImage(props) {
    const height=props.height==null?"50vh":props.height;
    const padding_top=props.padding_top==null?"20vh":props.padding_top;
    const media = props.media;
    if (!media) return (<>
    <p>no media</p>
    </>);
      
    return (
        <>
        
        <div style={{background: 'lightblue url("'+media+'") no-repeat scroll center',backgroundSize: 'cover',  height: height, paddingTop:padding_top}}> 
        <center><span style={{fontSize: "6em",color:"white"}}>{props.title}</span></center>
        
              </div>
       
        </>
    )
}

