import React, { useEffect, useState, Component } from "react";

import styled from "styled-components"

import FeaturedImage from "../../components/widget/feature-image"

import useCollapse from "react-collapsed";
import {
    cHeight,
    mHeight,
    isMobileView,
    getPlatform,
  } from "../../utils/responsiveness";


const LogoImage = styled.img`
height: ${cHeight(20)};
padding-top: 2.6vw;
padding-left: 2.6vw;
`

const Halflengthdiv1 = styled.div`
width: 32vw;
float:left;
position: absolute;
z-index: 50;
`

const Halflengthdiv2 = styled.div`
width: 68vw;
float:left;
margin-left : 32vw;
position: absolute;
overflow: hidden;
z-index: 50;
`
// Font styles
const Title = styled.div`
@import url("https://p.typekit.net/p.css?s=1&k=mla7iff&ht=tk&f=10879.10880.10881.10882.10883.10884.10885.10886.10887.10888.15586.15587.15357.15358.15359.15360.15361.15362.15363.15364.32874.32875&a=11641042&app=typekit&e=css");

@font-face {
  font-family:"futura-pt";
  src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}
position: absolute;
margin-top: ${cHeight(25)};
font-family: "futura-pt";
width: 30vw;
z-index: 51;
`

const para = styled.div`
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

@font-face {
  font-family: "Roboto";
  src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap")
      format("woff"),
    url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

margin-top: 25vh;
font-family: "futura-pt";
width: 25vw;
z-index: 51;
`;

const TitleSpan = styled.div`
text-align: right;
margin-right: 2vw;
`

const SubTitle = styled.div`
position: absolute;
width: 28vw;
color: #323232;
font-weight: 600;
font-size: 2vw;
font-family: "futura-pt";
text-align: right;
margin-right: 4vw;
margin-top: ${cHeight(60)};
`
const Description = styled.div`
height: auto;
color: #767676;
font-family: "Roboto";
font-weight: 300;
font-size: 2vw;
text-align: left;
width: 50vw;
margin: auto;
`
const ImageContainer1= styled.div`
width: 45vw;
`

const Image1= styled.img`
width: 45vw;
height: ${cHeight(50)};
margin: auto;
`
const Box= styled.div`
margin: auto;
margin-bottom: ${cHeight(3)};
border: 1px solid #dbd7d7;
display: flex;
flex-direction: column;
width: 30vw;
height: ${cHeight(50)};
font-family: "Roboto";
font-weight: 300;
`

const VideoContainer1= styled.div`
width: 22vw;
height: ${cHeight(40)};
`
const VideoBox1= styled.div`
width: 15vw;
margin-left: 4vw;
margin-right: 3vw;
height: ${cHeight(40)};
border: 1px solid #dbd7d7;
text-align: right;
`
const VideoBox2= styled.div`
width: 15vw;
background: hsla(199, 98%, 18%, 1);
background: linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
background: -moz-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
background: -webkit-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#013E5B", endColorstr="#1D749C", GradientType=1 );
height: ${cHeight(40)};
text-align: right;
`
const Video= styled.img`
width: 38vw;
height: ${cHeight(40)};
`
