import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import OurGroup from "../templates/ourGroup/ourGroup";

const WpPost = ({data}) => {
  const post = data;
  console.log(post)
  return (
      <Layout>
          
      </Layout>
  )
}

export const query = graphql`
  {
    wpPage(slug: {eq: "our-group"}) {
      id
      date
      databaseId
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      link
      slug
      template {
        ... on WpTemplate_Our_Group {
          templateName
          ourgroup {
            history {
              fieldGroupName
              mainHeading
              paragraph1
              paragraph2
              paragraph3
              paragraph4
              subHeading
            }
            groupOperatingCommittee {
              description
              designation
              fieldGroupName
              name
            }
            boardOfDirectors {
              description
              designation
              fieldGroupName
              name
            }
            groupExecutiveCommittee {
              name
              fieldGroupName
              designation
              description
            }
            cgcDescription
            historyDescription
            ourGroupDescription {
              paragraph7
              paragraph6
              paragraph5
              paragraph4
              paragraph3
              paragraph2
              paragraph1
              fieldGroupName
            }
            unDescription
            sustainabilityDescription
            subTitle1
            risksDescription
          }
          acfdescription {
            description
            fieldGroupName
            titleFont1
            titleFont2
          }
        }
      }
    }
  }
`

export default WpPost