import React, { useEffect, useState,Component } from "react"
import styled from "styled-components"

import FeaturedImage from "../../components/widget/feature-image"
import {cHeight,mHeight,isMobileView, getPlatform} from "../../utils/responsiveness"
import { Link } from "gatsby" 

/**
 * 
 * @param {*} prop 
 * @returns Mobile template
 */
 export function Mobile(prop) {
    const state = prop.state;
    const data = prop.post;
    const post = data.wpPage.template.ourgroup;
    const media = data.wpPage.featuredImage.node.mediaItemUrl;
    
    if (data== null) return (<><h1>Oh no 404!</h1></>)
  
    return(
      <>
        <div style={{display:"flex", flexDirection: "column", width: "100vw", height: "auto"}}>
            <div style={{zIndex: "50"}}>
            <Halflengthdiv1Mob>
              <LogoImageMob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/8186_Logo-jkh-1.gif" alt="" srcset="" />
            </Halflengthdiv1Mob>
            <TitleMob className="title">
              <TitleSpanMob> 
                  <span style={{textAlign: "center",color:"#2C4E6A", fontSize:"9vw"}}>
                      {post.acfdescription.titleFont1}
                  </span><br />
                  <span style={{textAlign: "center",color:"#636363", textTransform:"uppercase", fontSize:"9vw"}}>
                      {post.acfdescription.titleFont2}
                  </span>
              </TitleSpanMob>
            </TitleMob>
  
            <SubTitleMob style={{backgroundColor:"white"}}>
              <span> {post.ourGroupDescription.paragraph1}</span> 
            </SubTitleMob>
            <Halflengthdiv2Mob><FeaturedImage height={cHeight(100)} padding_top={"10px"} media={media} title="" style={{zIndex: "50"}}></FeaturedImage></Halflengthdiv2Mob>
          </div>
        </div>
        <div style={{display:"flex", flexDirection: "column", width: "80vw", margin:"auto"}}>
          <DescriptionMob>
            <span> {post.ourGroupDescription.paragraph1}</span> 
          </DescriptionMob>
          <readMore></readMore>

        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(10)}}>
          <ImageContainer1Mob>
              <Image1Mob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/our-group-image1.jpg"></Image1Mob>
          </ImageContainer1Mob>
  
          <BoxMob>
            <span style={{fontSize: "7vw", width: "80vw", margin:"auto", marginTop: cHeight(6), marginBottom: cHeight(6), textAlign: "center"}}>HISTORY</span>
            <span style={{fontSize: "5vw", width: "80vw", margin:"auto", color:" #767676"}}>{post.history.paragraph1}</span>
            <readMore></readMore>

          </BoxMob>
  
        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(15)}}>
          <VideoContainer1Mob>
             <VideoBox1Mob>
               <span style={{position: "relative", top:  cHeight(5.21), marginTop:  cHeight(10), color:"#636363", textTransform:"uppercase", fontSize:"5vw"}}>OUR</span><br />
               <span style={{position: "relative", top:  cHeight(6), color:"#2C4E6A", textTransform:"uppercase", fontSize:"5vw"}}>BRANDS</span>
             </VideoBox1Mob>
          </VideoContainer1Mob>
  
          <div style={{display:"flex", flexDirection: "row", width: "100vw", margin:"auto"}}>
          <VideoBox2Mob>
            <span style={{marginRight: "2vw", position: "relative", top:  cHeight(9), color:"#ffffff", textTransform:"uppercase", fontSize:"3.5vw"}}>LEADERSHIP</span>
          </VideoBox2Mob>
          <VideoMob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/leadership-video-thumbnail.jpg"></VideoMob>
          </div>
  
        </div>
  
        <div style={{textAlign: "center", width: "100vw", margin:"auto", marginLeft:"2vw", marginTop: cHeight(40)}}>
          <span style={{color:"#2C4E6A", textTransform:"uppercase", fontSize:"6vw"}}>Governance</span>
        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(15)}}>
  
          <div className="labelbox">
            <div style={{textAlign: "center", padding:"2vw"}}>
              <span style={{color:"#00D1FF"}}>
              Corporate 
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              Governance 
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              Commentary
              </span>
            </div>
          </div>
          
          <div className="descriptionbox">
            <div style={{padding:"2vw", margin:"auto"}}>
              <span>{post.cgcDescription}</span>
            </div>
            <readMore></readMore>

          </div>
  
        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(15)}}>
  
          <div className="labelbox">
            <div style={{padding:"2vw", textAlign: "center"}}>
              <span style={{color:"#00D1FF"}}>
              Sustainability Integration,
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              Stakeholder Engagement
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              & Materiality
              </span>
            </div>    
          </div>
  
          <div className="descriptionbox">
            <div style={{padding:"2vw", margin:"auto"}}>
              <span>{post.sustainabilityDescription}</span>
            </div>
            <readMore></readMore>

          </div>
  
        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(15)}}>
  
          <div className="labelbox">
            <div style={{textAlign: "center", padding:"2vw"}}>
              <span style={{color:"#00D1FF"}}>
              UN Global 
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              Compact Initiatives
              </span><br />
            </div>
          </div>
          
          <div className="descriptionbox">
            <div style={{padding:"2vw", margin:"auto"}}>
              <span>{post.unDescription}</span>
            </div>
            <button className="readmore-btn">
              Read More
            </button>
          </div>
  
        </div>
  
        <div style={{display:"flex", flexDirection: "column", width: "100vw", margin:"auto", marginTop: cHeight(15), marginBottom: cHeight(15)}}>
  
          <div className="labelbox">
            <div style={{padding:"2vw", textAlign: "center"}}>
              <span style={{color:"#00D1FF"}}>
              Risks
              </span><br />
              <span style={{color:"#ffffff", textTransform:"uppercase"}}>
              Opportunities  
              </span><br />
              <span style={{textAlign: "left",color:"#ffffff", textTransform:"uppercase"}}>
              & Internal Controls
              </span>
            </div>    
          </div>
  
          <div className="descriptionbox">
            <div style={{padding:"2vw", margin:"auto"}}>
              <span>{post.risksDescription}</span>
            </div>
            <readMore></readMore>
          </div>
  
        </div>
  
  
      </>
    );
  }

  function readMore() {
      return (
          <>
            <readmoreBtn>
              Read More
            </readmoreBtn>
          </>
      )
  }

  function PdfLinks(props) {
    return (<>
    <Link target="_blank" onClick={props.link} link={props.link}
      style={{
      height:  cHeight(6),
      width: "10vw",
      background: "rgb(249 249 249)",
      border: "1px solid #dbd7d7",
      cursor: "pointer",
      float: "right",
      color: "#767676",
      textAlign: "right",
      fontSize: "1vw",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      fontFamily: "'Roboto'"
      }}
    >{props.name}
    </Link>
    </>)
  }
  

/*Mobile Version*/

const LogoImageMob = styled.img`
width: 14vw;
height: ${cHeight(35)};
padding-top: 2.6vw;
padding-left: 2.6vw;
`

const Halflengthdiv1Mob = styled.div`
width: 100vw;
z-index: 50;
`

const Halflengthdiv2Mob = styled.div`
width: 100vw;
overflow: hidden;
z-index: 50;
`

const TitleMob = styled.div`
@import url("https://p.typekit.net/p.css?s=1&k=mla7iff&ht=tk&f=10879.10880.10881.10882.10883.10884.10885.10886.10887.10888.15586.15587.15357.15358.15359.15360.15361.15362.15363.15364.32874.32875&a=11641042&app=typekit&e=css");

@font-face {
  font-family:"futura-pt";
  src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}
margin-top: ${cHeight(25)};
font-family: "futura-pt";
width: 100vw;
z-index: 51;
`

const TitleSpanMob = styled.div`
font-weight: bold;
color: #013E5B;
text-align: center;
`

const SubTitleMob = styled.div`
width: 70vw;
color: #2C4E6A;
font-weight: 600;
font-size: 6vw;
text-align: center;
margin: auto;
margin-top: ${cHeight(10)};
margin-bottom: ${cHeight(10)};
`

const DescriptionMob = styled.div`
height: auto;
color: #767676;
font-weight: 200;
font-size: 5vw;
font-family: "Roboto";
font-weight: 300;
text-align: left;
width: 80vw;
margin: auto;
margin-top:  ${cHeight(10)};
`

const ImageContainer1Mob= styled.div`
width: 100vw;
`

const Image1Mob= styled.img`
width: 100vw;
height: auto;
margin: auto;
`
const BoxMob= styled.div`
height: auto;
margin: auto;
margin-bottom: ${cHeight(3)};
width: 100vw;
display: flex;
flex-direction: column;
font-family: "Roboto";
font-weight: 300;
`

const VideoContainer1Mob= styled.div`
width: 100vw;
height: ${cHeight(40)};
`
const VideoBox1Mob= styled.div`
width: 100vw;
height: ${cHeight(40)};
text-align: center;
`
const VideoBox2Mob= styled.div`
width: 25vw;
background: hsla(199, 98%, 18%, 1);
background: linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
background: -moz-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
background: -webkit-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#013E5B", endColorstr="#1D749C", GradientType=1 );
height: auto;
text-align: right;
`
const VideoMob= styled.img`
width: 75vw;
height: auto;
`

const labelbox = styled.div`
    width: 100vw;
    background: hsla(199, 98%, 18%, 1);
    background: linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(199, 98%, 18%, 1) 0%, hsla(199, 69%, 36%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#013E5B", endColorstr="#1D749C", GradientType=1 );
    border: 1px solid #2C4E6A;
    height: ${cHeight(50)};
    font-size: 5vw;
  `
const descriptionbox = styled.div`
    height: auto;
    margin: auto;
    width: 80vw;
    color: #767676;
    font-size: 5vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: ${cHeight(20)};
    font-family: "Roboto";
    font-weight: 300;
  `
const readmoreBtn = styled.div`
    height:  ${cHeight(25)};
    width: 40vw;
    background: rgb(249 249 249);
    border: 1px solid #dbd7d7;
    cursor: pointer;
    float: right;
    color: #767676;
    text-align: right;
    font-size: 4vw;
    text-transform: uppercase;
    margin: auto;
    margin-top: ${cHeight(20)};
    margin-bottom: ${cHeight(20)};
  `